<script setup lang="ts">
import { CmsBlockProductThreeColumn } from "@shopware-pwa/composables-next";

const props = defineProps<{
  content: CmsBlockProductThreeColumn;
}>();

const { getSlotContent } = useCmsBlock(props.content);

const leftContent = getSlotContent("left");
const rightContent = getSlotContent("right");
const centerContent = getSlotContent("center");
</script>

<template>
  <div class="grid md:grid-cols-3 gap-5 !m-0 px-5 lg:px-15">
    <ProductCardNew :product="leftContent.data.product || {id: leftContent.config?.product?.value }" />
    <ProductCardNew :product="centerContent?.data?.product || {id: centerContent.config?.product?.value }" />
    <ProductCardNew :product="rightContent?.data?.product || {id: rightContent.config?.product?.value }" />
  </div>
</template>
